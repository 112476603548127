@import 'styles/common.scss';

.container {
  @include mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100svh;
    border-radius: 20px 20px 0 0;
  }
  @include desktop {
    position: relative;
  }
  .content {
    @include mobile {
      padding: 32px 16px 16px;
    }
    @include desktop {
      padding: 48px 40px;
    }
  }
  .contentSaveForLater {
    padding: 24px;
  }
  .cancelIcon {
    position: absolute;
    top: 56px;
    right: 16px;
    padding: 10px;
    color: #080c0bbd;
    border-radius: 999px;
    background: rgba(8, 12, 11, 0.05);
    z-index: $zindex-modal;
    @include mobile {
      top: 8px;
      right: 8px;
      padding: 8px;
    }
  }
}
