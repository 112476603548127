@import 'styles/common.scss';

.icon {
  svg {
    width: auto;
  }
  &.dotPrimary {
    :global(.dot) {
      fill: var(--primary-color);
    }
  }
  &.dotBlack {
    :global(.dot) {
      fill: var(--text-primary);
    }
  }
}

.size {
  &XSmall {
    height: 24px;
  }
  &Small {
    height: 28px;
  }
  &Medium {
    height: 32px;
  }
  &Large {
    height: 36px;
  }
}
.color {
  &White {
    color: var(--bg-primary);
  }
  &Black {
    color: var(--text-primary);
  }
  &Primary {
    color: var(--primary-color);
  }
}

img.size {
  &Small {
    height: 20px;
  }
}

img.size {
  &XSmall {
    height: 20px;
  }
}

img.size {
  &XSmall {
    height: 20px;
  }
}