@import 'styles/common.scss';

.walkthrough {
  position: relative;
  display: inline-block;

  .highlight-border {
    button:first-of-type {
      border-color: var(--primary-color);
    }
  }
  .box-shadow {
    button:first-of-type {
      box-shadow: 0px 0px 0px 3px var(--walkthrough-box-shadow);
    }
  }

  .walkthrough-position {
    position: absolute;
    width: 240px;
    padding: 14px;
    border-radius: 12px;
    z-index: $zindex-sticky;
  }

  .color {
    &Dark {
      $background-color: var(--surface-overlay-max);
      background: $background-color;
      backdrop-filter: blur(50px);
      color: var(--accent-text);
      .arrow-container {
        color: $background-color;
      }
    }
    &Blue {
      $background-color: var(--text-icon-interactive);
      background: $background-color;
      backdrop-filter: blur(50px);
      color: var(--accent-text);
      .arrow-container {
        color: $background-color;
      }
    }
  }

  .position {
    &Top {
      bottom: 100%;
      margin-bottom: 6px;
      .arrow-container {
        width: 100%;
        top: calc(100%);
        left: 0;
      }
    }
    &Bottom {
      top: 100%;
      margin-top: 6px;
      .arrow-container {
        width: 100%;
        bottom: calc(100%);
        left: 0;
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
    &Left {
      right: 100%;
      margin-right: 6px;
      .arrow-container {
        height: 100%;
        left: calc(100%);
        top: 0;
      }
      .arrow {
        transform: rotate(180deg);
      }
    }
    &Right {
      left: 100%;
      margin-left: 6px;
      .arrow-container {
        height: 100%;
        right: calc(100%);
        top: 0;
      }
    }
  }
  .horizontal {
    &Start {
      left: 0;
      .arrow-container {
        width: calc(100% - 10px);
        left: 10px;
        justify-content: flex-start;
      }
    }
    &Center {
      left: 50%;
      transform: translateX(-50%);
    }
    &End {
      right: 0;
      .arrow-container {
        width: calc(100% - 10px);
        right: 10px;
        justify-content: flex-end;
      }
    }
  }
  .vertical {
    &Start {
      top: 0;
      .arrow-container {
        height: calc(100% - 10px);
        top: 10px;
      }
      .arrow {
        align-self: flex-start;
      }
    }
    &Center {
      top: 50%;
      transform: translateY(-50%);
    }
    &End {
      bottom: 0;
      .arrow-container {
        height: calc(100% - 10px);
        bottom: 10px;
      }
      .arrow {
        align-self: flex-end;
      }
    }
  }

  .arrow-container {
    @include flex-center;
    position: absolute;
  }

  .walkthrough-container {
    white-space: normal;

    .walkthrough-title {
      @include f-inter-regular-2(1.3rem, 1.8rem);
    }
    .walkthrough-desc {
      @include f-inter-regular(1.2rem, 1.7rem);
      padding-top: 4px;
    }
    .action-container {
      @include flex(center);
      column-gap: 8px;
      padding-top: 12px;

      &.has-progress {
        justify-content: space-between;
      }

      .progress-container {
        @include flex(center, flex-start);
        column-gap: 4px;

        .dot {
          height: 6px;
          width: 6px;
          min-width: auto;
          color: var(--bg-primary-light);
          opacity: 0.16;
        }
        .isCurrent {
          opacity: 1;
        }
      }

      .walkthrough-button {
        @include f-inter-semi-bold(1.2rem, 1.7rem);
        min-width: 64px;
        padding: 5.5px 10px;
        border-radius: 6px;
      }
    }
  }
}
