@import 'styles/common.scss';

.SignIn {
  @include flex-column(center, center);
  position: relative;
  @include mobile {
    width: 100%;
    height: 100%;
  }

  .logo{
    @include mobile{
      height: 28px; 
    }
  }

  .join-society {
    .society-logo {
      margin-bottom: 8px;
    }

    .title {
      @include f-inter-bold(2rem, 2.8rem);
      color: var(--text_icon-primary);
    }

    .societyText{
      @include flex-column(flex-start, null);
      margin-top: 20px;
      padding: 16px 12px 16px 20px;
      gap: 8px;
      align-self: stretch;
      border-radius:  12px;
      background: var(--surface-brand-silent);
      .societyTextContainer{
        @include flex(center,center);
        gap: 8px;
        .icon{
          color: var(--primary-color);
        }
        .text{
          @include f-inter-regular(1.6rem,2.2rem);
          color: var(--text-icon-on-lights-secondary);
        }
      }
    }

    @include mobile{
      padding: 0 8px;

      .society-logo {
        margin-bottom: 4px;
      }

      .societyText{
        margin-top: 12px;
      }
    }
  }

  @include desktop {
    background: var(--ui-teal-light-bg-primary);
    border-radius: 16px;
    box-shadow: 0px 8px 16px 0px rgba(8, 12, 11, 0.09);
    width: 530px;
    max-width: 530px;
  }
  .content{
    @include flex-column(center);
    @include mobile {
      padding: 0 16px;
    }
    @include desktop{
      padding: 48px 40px 30px;
    }
  }
  .content,.contentPopup{
    width:100%;
  }
  .chevron {
    position: absolute;
    @include mobile {
      top: 0;
      left: 0;
    }
    @include desktop {
      top: 18px;
      left: 18px;
    }
  }

  .title {
    @include f-inter-bold(2.4rem, 3.2rem, 2rem, 2.8rem);
    text-align: center;
    &Mobile {
      @include mobile {
        @include f-inter-regular-2(1.4rem, 2rem);
        padding-top: 12px;
      }
    }
  }
  .desc {
    @include f-inter-regular(1.4rem, 2.2rem, 1.3rem, 1.8rem);
    margin-top: 8px;
    text-align: center;
    @include mobile {
      margin: 8px 8px 0px 8px;
    }
  }

  .actionContainer {
    width: 100%;
    @include mobile {
      padding: 40px 0 125px;
    }
    @include desktop {
      padding: 40px 0 75px;
    }
  }
  .actionContainerSociety{
    @include mobile{
      padding: 24px 0 16px;
    }
    @include desktop{
      margin-top: 32px;
      padding: 0;

    }
  }
  .gap {
    padding: 48px 0 90px;
  }
  .error {
    @include flex(center, space-between);
    color: rgba(8, 12, 11, 0.74);
    padding: 10px 8px 10px 12px;
    margin-top: 8px;
    border-radius: 12px;
    background: var(--ui_teal_light-surface-warning-silent);
    column-gap: 10px;

    a {
      color: var(--text-icon-interactive);
    }
    .cancelError {
      height: 32px;
      width: 32px;
      padding: 7px;
      color: rgba(8, 12, 11, 0.74);
    }
  }
  .button {
    &Google,
    &Apple,
    &Continue {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      border-radius: 14px;
      width: 100%;
    }
    &Apple,
    &Continue {
      margin-top: 16px;}
  }
  .startIcon {
    height: 22px;
    width: 22px;
    margin-right: 12px;
  }
  .separator {
    border-top: 1px solid var(--border-on-lights-15);
    width: 40%;
    margin: 0 8px;

    &Row {
      color: var(--text-icon-on-lights-tertiary);
      margin: 20px 0;
      @include mobile {
        margin: 16px 0;
      }
    }

    &Text {
        @include f-inter-regular-2(1.2rem, 1.7rem);
    }
  }
  .signInput {
    @include f-inter-regular(1.8rem, 2.5rem);
    border: 1.5px solid #080c0b26;
    border-radius: 12px;
    &:focus {
      border: 2px solid #080c0b4d;
    }
  }

  .terms {
    &Row {
      padding-top: 32px;
    }
  }
  .termsRow,
  .termsText {
    @include f-inter-regular(1.3rem, 1.8rem);
    text-align: center;
    color: var(--text-icon-on-lights-tertiary);
  }
  .logos{
    position: absolute;
    bottom:0;

    background: var(--surface-overlay-minimal);
    width: 100%;
    @include mobile{
      padding: 32px 16px;
    }
    @include desktop{
    padding: 24px 48px;}
    &Text{
      @include f-inter-regular(1.3rem,1.8rem);
      color: var(--text-icon-on-lights-tertiary);
      text-align: center;
      padding-bottom: 16px;
    }
    &Icon{
      width: 75px;
      height: 14px;
    }
  }
}
.verifyOTP{
  @include desktop{
    padding: 56px 48px;
  }
}
.verifyOTPPopup {
  @include desktop {
    padding: 8px;
  }
}
.otpContainer {
  @include desktop {
    width: 456px;
  }
}
.hideMobile {
  @include mobile {
    display: none;
  }
}
.hideDesktop {
  @include desktop {
    display: none;
  }
}

.producthunt-info {
  @include flex-column;
  .producthunt-title {
    color: var(--text-primary-v2);
  }
  .producthunt-offer {
    color: var(--producthunt-theme-color);
  }
  .producthunt-title, .producthunt-offer {
    text-align: center;
  }
  @include mobile {
    gap: 12px;
    margin-top: 12px;
    .producthunt-title {
      @include ui-text-ui-14-medium;
    }
    .producthunt-offer {
      @include f-inter-bold(3.4rem, 4.6rem);
    }
  }
  @include desktop {
    gap: 10px;
    .producthunt-title {
      @include heading-h-18-medium;
    }
    .producthunt-offer {
      @include f-inter-bold(4rem, 4.6rem);
    }
  }
}

.producthunt-banner {
  @include flex(center, space-between);
  background-color: var(--producthunt-theme-color);
  width: 100%;
  color: var(--bg-primary-light);
  .branding {
    @include flex(center);
    column-gap: 8px;
  }
  @include mobile {
    position: fixed;
    top: 0;
    padding: 8px;
    .title {
      @include fns-bold(1.6rem, 1.8rem);
      font-family: Helvetica;
    }
    .tag {
      @include ui-text-ui-14-semibold;
    }
  }
  @include desktop {
    border-radius: 16px 16px 0 0;
    padding: 10px;
    .title {
      @include fns-bold(1.8rem, 2.1rem);
      font-family: Helvetica;
    }
    .tag {
      @include ui-text-ui-16-semibold;
    }
  }
}
