@import 'styles/common.scss';

.Modal {
  @include flex-center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7) !important;
  transition: all 300ms ease-in-out;
  z-index: $zindex-modal;
  & + .Modal{
    background:none;
  }
  &Content {
    width: auto;
    height: auto;
    overflow-x: auto;
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(74, 166, 206, 0.1);
  }
  $p: &;
  &FullScreen {
    background: transparent;
    #{$p}Content {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}
