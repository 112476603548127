@import 'styles/common.scss';

.HamburgerNavMenu {

    &[data-headlessui-state='open'] {
        .HamburgerNavMenuTrigger {
            background-color: var(--surface-pressed-surface-primary-pressed);
        }
    }

    &Trigger {
        position: relative;
        padding: 9.5px;
        border-radius: 10px;
        border: 1.5px solid var(--border-on-lights-15);
        background: var(--accent-text);
        box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);
        cursor: pointer;

        &:hover {
            background: var(--ui-teal-light-surface-overlay-silent);
        }
    }

    &List {
        min-width: 210px;
        width: auto;
        position: absolute;
        background: var(--bg-primary);
        box-shadow: 0px 4px 8px 0px rgba(8, 12, 11, 0.08);
        border-radius: 10px;
        border: 0.5px solid var(--border-on-lights-15);
        top: 60px;
        right: 20px;
        z-index: 200;
        padding: 4px 0;
        outline: none;
    }

    &Button {
        @include f-inter-regular-2(1.6rem, 2.6rem);
        @include flex(center);
        width: 100%;
        padding: 9px 12px;
        background: none;
        border: none;
        column-gap: 12px;
        cursor: pointer;

        &:last-of-type {
            border: none;
        }

        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--text-primary-v2);
        }

        .getStarted {
            @include flex(center);
            color: var(--text-icon-interactive);
            column-gap: 12px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background: #f7f7f7;
            border-radius: 8px;
        }
    }

    .divider {
        margin: 4px 0;
        border: 0.5px solid var(--border-on-lights-10);
    }
}
