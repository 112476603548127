@import 'styles/common.scss';

.Avatar {
  position: relative;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-teal-light-surface-tertiary);
  text-align: center;
  flex-shrink: 0;
  color: var(--text-secondary);
  text-transform: uppercase;
  border: 0.5px solid var(--border-on-lights-10);
  cursor: default;
  img {
    background-color: var(--bg-primary-light);
    flex-grow: 0;
    flex-shrink: 0;
  }
  &Size {
    &Xxsmall {
      @include f-inter-semi-bold(1.1rem, 1.5rem);
      height: 20px;
      width: 20px;
    }
    &Xsmall {
      @include f-inter-semi-bold(1.1rem, 1.5rem);
      height: 28px;
      width: 28px;
    }
    &Small {
      @include f-inter-semi-bold(1.4rem, 2rem);
      height: 32px;
      width: 32px;
    }
    &36 {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      border-width: 2px;
      height: 36px;
      width: 36px;
    }
    &Medium, &40 {
      @include f-inter-semi-bold(1.8rem, 2.5rem);
      height: 40px;
      width: 40px;
    }
    &Large {
      @include f-inter-semi-bold(2rem, 2.8rem);
      height: 48px;
      width: 48px;
    }
    &Xlarge {
      @include f-inter-semi-bold(2rem, 5.6rem);
      height: 56px;
      width: 56px;
    }
    &Xxlarge {
      @include f-inter-semi-bold(2.8rem, 3.8rem);
      border-width: 1px;
      height: 64px;
      width: 64px;
    }
    &Xxlarge {
      @include f-inter-semi-bold(2.8rem, 3.8rem);
      border-width: 1px;
      height: 64px;
      width: 64px;
    }
    &80 {
      @include f-inter-semi-bold(3.4rem, 4.6rem);
      border-width: 2px;
      height: 80px;
      width: 80px;
    }
    &Xxxlarge,
    &100 {
      @include f-inter-semi-bold(4rem, 4.6rem);
      border-width: 2px;
      height: 100px;
      width: 100px;
    }
  }
  &Badge {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .verifiedIcon {
    svg > rect {
      fill: var(--primary-color);
    }
  }
}
