@import 'styles/common.scss';

$danger-color: #ec3838;

.ActionMenuButton {
    position: relative;

    .buttonWrapper {
        padding: 0;
        border: none;
        background: transparent;

        &Button {
            .downArrow {
                width: 12px;
                height: 12px;
            }
        }
    }

    ul {
        min-width: max-content;
        width: auto;
        position: absolute;
        background: var(--bg-primary-light);
        box-shadow: 0px 4px 8px 0px rgba(8, 12, 11, 0.08);
        border: 0.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
        border-radius: 10px;
        outline: none;

        &:focus {
            outline: 0;
        }

        .item {
            @include flex(center, space-between);
            @include f-inter-regular-2(1.4rem, 2.2rem);
            column-gap: 16px;
            width: auto;
            min-width: 100%;
            height: 44px;
            padding: 11px 14px;
            color: var(--black-color);
            white-space: nowrap;
            background: none;
            border: none;
            cursor: pointer;
            &.danger {
                color: $danger-color;
            }

            &.primaryColor {
                svg {
                    color: var(--primary-color);
                }
            }

            &.secondaryColor {
                color: #a1a3a4;
            }

            &:focus {
                outline: 0;
            }

            &[aria-expanded='true'],
            &:hover {
                background-color: var(--bg-secondary);
            }

            .actionElement > * {
                width: 40px;
            }

            .label {
                @include multi-line-ellipsis(1);
                text-align: start;
                word-break: break-all;
            }
        }

        .option {
            .subContainer {
                .optionsItem {
                    border-radius: 10px;
                }
            }
            .item {
                border-radius: 10px;
            }
        }
    }

    .options {
        z-index: 3;
        &Top {
            top: 120%;
        }
        &Bottom {
            bottom: 120%;
        }

        &.left {
            right: -6px;
        }

        &.right {
            left: -6px;
    }
    }

    .subContainer {
        position: relative;

        .optionsItem {
            @include flex(center, space-between);
            padding: 11px 14px;
            @include f-inter-regular(1.5rem, 1.6rem);
            cursor: pointer;

            &[aria-expanded='true'],
            &:hover {
                background-color: var(--bg-secondary);
            }
        }
    }

    .subOptions {
        top: 8px;

        &.left {
            right: 200px;
        }

        &.right {
            left: 75px;
        }
    }
}

.enter,
.leave {
    transition: all 100ms ease-in-out;
}

.enterFrom,
.leaveTo {
    opacity: 0;
    transform: scale(0.95);
}

.enterTo,
.leaveFrom {
    opacity: 1;
    transform: scale(1);
}
