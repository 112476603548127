@import 'styles/common.scss';

.CVUpload {
  background: #f6f6fa;
  .content {
    @include medium {
      padding: 16px 28px;
    }
    @include small {
      padding: 0;
    }
    @include large {
      padding: 16px 28px;
    }
  }
  .title {
    @include f-inter-semi-bold(1.6rem, 1.8rem, 1.7rem, 2.1rem);
    text-align: center;
  }
  .desc {
    @include f-inter-regular(1.5rem, 2.1rem, 1.6rem, 2.6rem);
    color: rgba(9, 20, 37, 0.74);
    padding: 27px 0 16px;
  }
  .dropbox {
    @include flex-column(center, center);
    background: var(--bg-primary);
    border: 1px dashed rgba(9, 20, 37, 0.3);
    border-radius: 12px;
    padding: 40px;
    &Title {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      @include flex(center);
      margin: 16px 0;
    }
    &Button {
      @include f-inter-semi-bold(1.6rem, 2.2rem);
      color: var(--link-color);
      padding: 0;
      margin: 0;
    }
    &Desc {
      @include f-inter-regular(1.3rem, 1.6rem);
      color: rgba(100, 101, 104, 1);
    }
    &Blur {
      filter: opacity(0.3);
    }
  }
  .separator {
    border-bottom: 1px solid #ababab;
    margin-top: 28px;
  }
  .section {
    background: var(--bg-primary);
    padding: 28px;
    @include flex(center, flex-end);
  }
  .file {
    background: var(--bg-primary);
    border-radius: 12px;
    padding: 16px 0 16px 16px;
    margin-top: 27px;
    &Type {
      @include f-inter-bold(1.1rem, 1.3rem);
      color: var(--primary-color);
      padding: 18px 15px;
      background: #f6f6fa;
      border-radius: 8px;
      margin-right: 16px;
      @include sm-desktop-down {
        width: 19%;
      }
    }
    &Info {
      @include sm-desktop-down {
        width: 60%;
      }
    }
    &Name {
      @include f-inter-semi-bold(1.6rem, 2.1rem);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &Time {
      @include f-inter-regular(1.3rem, 1.6rem);
      color: #646568;
    }
    .actionmenu {
      margin-top: 5px;
      @include sm-desktop-down {
        width: 10%;
      }
    }
  }
  .back {
    @include flex(center);
    position: absolute;
    left: 12px;
    @include small {
      top: 8px;
    }
    @include large {
      color: rgba(100, 101, 104, 1);
      top: 13px;
    }
    cursor: pointer;
  }
  .loader {
    position: absolute;
    left: 47%;
    z-index: 1;
    @include medium {
      top: 45%;
    }
    @include small {
      top: 25%;
    }
    @include large {
      top: 45%;
    }
  }
}
