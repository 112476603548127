@import 'styles/common.scss';

.container {
  position: relative;
  @include small {
    width: calc(100vw - 24px);
  }
  .content {
    @include small {
      padding: 20px;
    }
    @include large {
      padding: 28px;
    }
  }
  .cancelIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--primary-color-disabled);
    z-index: $zindex-modal;
  }
}
