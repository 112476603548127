@import 'styles/common.scss';

.container {
  @include mobile {
    width: 100%;
    padding: 0 16px;
  }

  .title {
    @include f-inter-bold(2.8rem, 3.8rem);
    text-align: center;
  }
  .desc {
    @include f-inter-regular(1.4rem, 2.2rem);
    padding-top: 8px;
    text-align: center;
  }
  .mb24 {
    margin: 56px 0 24px;
  }
  .confirmationCodeWrapper {
    @include flex(center);
    gap: 12px;
    input {
      text-align: center;
      @include f-inter(2rem, 3.2rem);
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .otpinput {
    padding: 14.5px 18px;
  }
  .errorMessage {
    @include flex(center);
    color: rgba(229, 19, 46, 1);
    @include f-inter-regular-2(1.2rem, 1.7rem);
    padding-top: 6px;
    .icon {
      margin-right: 5px;
    }
  }
  .message {
    color: rgba(8, 12, 11, 0.62);
    @include f-inter(1.4rem, 2rem);
    margin-top: 24px;
  }
  .timerContainer {
    @include f-inter(1.4rem, 2rem);
    color: rgba(8, 12, 11, 0.62);
    margin-top: 48px;

    @include flex(center, center);
    .timer {
      margin-right: 4px;
      padding-right: 4px;
      min-width: 42px;
    }
    .resendButton {
      color: rgba(12, 101, 228, 1);
      cursor: pointer;
      padding-left: 4px !important;
      margin-left: 4px;
      &.disabled {
        cursor: not-allowed;
        color: rgba(8, 12, 11, 0.3);
        cursor: not-allowed;
      }
    }
  }
}
