@import 'styles/common.scss';

.container {
  position: relative;
  @include small {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 16px;
    background: rgba(246, 246, 250, 1);
  }
  @include large {
    width: 460px;
  }
  .cancelIcon {
    position: absolute;
    top: 10px;
    right: 16px;
    z-index: $zindex-modal;
  }
  .chevronIcon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
