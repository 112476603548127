@import 'styles/common.scss';

$shadow-color: rgba(0, 0, 0, 0.05);

.container {
  height: $public-pages-header-height-lg;
  position: sticky;
  top: 0;
  z-index: $zindex-fixed;
  background: var(--bg-primary);
  box-shadow: 0px 1px 2px $shadow-color;
  padding: 8px 40px;
  display: none;

  @include media-breakpoint-small {
    height: $public-pages-header-height-sm;
    padding: 22px 24px;

    &.shown-mobile {
      @include flex(center, space-between);
    }
  }

  &.display-header {
      @include media-breakpoint-medium-only {
        @include flex(center, space-between);
        min-height: $public-pages-header-height-lg;
        max-height: $public-pages-header-height-lg;
      }
      @include media-breakpoint-large {
        @include flex(center, space-between);
        min-height: $public-pages-header-height-lg;
        max-height: $public-pages-header-height-lg;
      }
  }

  &Temporary {
    background: var(--bg-primary-webapp);
  }

  .logoLink {
    display: inline-flex;
    cursor: pointer;
  }

  .tempcandidateheader {
    flex: 1;
    .download {
      width: 120px;
      height: 44px;
      padding: 0;
      @include large {
        border-radius: 14px;
      }
    }
  }
  .activeActionButton{
    background: var(--ui_teal_light-surface-primary-pressed);
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 4px 8px;
  z-index: $zindex-fixed + 1;
  display: none;
  &Scrolled {
    border-top: 0.5px solid var(--border-on-lights-15);
    box-shadow: 0px -1px 2px 0px rgba(35, 46, 63, 0.03);
    background: var(--ui-teal-light-bg-primary);
  }
  @include small {
    &.shown {
      display: block;
    }
  }
}

.options {
  @include desktop {
    width: 72px;
  }
  color: var(--border-on-lights-62);
  position: relative;
  @include flex-column(center);
  padding: 4px 12px 2px;
  border-radius: 8px;
  &:hover {
    @include desktop {
      background: var(--ui-teal-light-surface-overlay-silent);
      cursor: pointer;
    }
  }
  &Active {
    color: rgba(0, 148, 145, 1);
  }
  &Icon {
    width: 20px;
    height: 20px;
  }

  &Text {
    padding-top: 4px;
    @include f-inter-regular-2(1rem, 1.4rem);
  }
}
.hamburger {
  padding: 0;

  .optionsIcon,
  .optionsText {
    color: var(--border-on-lights-62);
  }

  &[data-headlessui-state='open'] {
    background-color: var(--border-on-lights-10);
  }

  .trigger {
    @include flex-column(center);
    position: relative;
    cursor: pointer;
    width: 100%;
    padding: 4px 12px 2px;
    border: none;
    background: none;

    .menu-icon {
      position: relative;
    }
  }

  .menuList {
    position: absolute;
    @include mobile {
      bottom: 48px;
      right: 0px;
    }
    @include desktop {
      top: 60px;
      right: 0;
    }
    min-width: 210px;
    width: auto;
    background: var(--bg-primary);
    box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
    border-radius: 10px;
    z-index: $zindex-popover;
    &:focus {
      outline: none;
    }
    &.footer {
      bottom: calc(100% + 8px);
      top: unset;
    }
  }
  .menu-group {
    padding: 4px;
  }
  .menuItem {
    position: relative;
    .new {
      position: absolute;
      right: 12px;
      @include flex-center;
    }
    .tooltipContainer {
      width: 300px;
      white-space: pre-line;
      &Content {
        width: 100%;
        text-align: start;
      }
    }
  }
  .menubutton {
    @include flex(center);
    @include f-inter-regular-2(1.6rem, 2.6rem);
    cursor: pointer;
    column-gap: 12px;
    padding: 11px 16px;
    background: none;
    border: none;
    width: 100%;
    color: rgb(8, 12, 11);
    padding: 9px 12px;
    &:last-of-type {
      border: none;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--black-color);
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background: #f7f7f7;
      border-radius: 8px;
    }
    &:disabled {
      color: var(--text-icon-disabled);
      cursor: default;
    }
  }
  .moremenu {
    position: relative;
    &List {
      @include mobile {
        bottom: 0;
        max-width: 100%;
        max-height: 300px;
      }
      @include desktop {
        top: 0;
        max-width: 320px;
        max-height: 300px;
      }
    }
  }
  .scroll {
    overflow-y: scroll;
  }
  .submenu {
    @include flex(center);
    flex-direction: row;
    &:hover {
      background-color: none;
    }
  }
}
.position {
  position: relative;
}
.notificationBadge {
  @include f-inter-regular(1.1rem, 1.5rem);
  @include flex-center;
  background: var(--text_icon-on_lights-critical);
  border-radius: 100px;
  color: var(--accent-text);
  height: 20px;
  min-width: 20px;
  padding: 2px 6px;

}
.notificationbubble {
  position: absolute;
  top: -10px;
  right: 0;
  transform: translateX(50%);
}
.messagebubble {
  position: absolute;
  top: -6px;
  right: 15px;
}
.matchesBubble {
    position: absolute;
    top: -6px;
    right: 16px;
    padding: 0;
    @include flex-center;
    @include f-inter-regular-2(1.1rem, 1.5rem);
}
.menuDivider {
  border-bottom: 1px solid var(--border-on-lights-10);
}
.toasterContent {
  @include flex(flex-start);
  column-gap: 10px;
  &Center {
    @include flex(center, center);
  }
  &Icon {
    font-size: 20px;
  }

  &Title {
    @include f-inter-bold(1.4rem, 1.8rem);
    color: var(--black-color);
  }

  &Footer {
    margin-top: 2px;
  }

  &Description {
    @include f-inter-regular(1.4rem, 1.8rem);
    color: var(--dark-grey);
    display: inline;
  }

  &Dismiss {
    @include f-inter-regular(1.4rem, 1.8rem);
    background-color: transparent;
    border: none;
    outline: none;
    color: #0166ff;
    cursor: pointer;
    &.leftPadding0 {
      padding-left: 0;
    }
  }
}
.hidemobile {
  @include hide-small();
}
.hidedesktop {
  @include hide-desktop();
}
.signInActions {
    @include flex-center;
    column-gap: 8px;
}
