@import 'styles/common.scss';

.container {
  @include flex-column(center, center);
  background: var(--bg-primary-light);
  width: 100%;
  flex: 1;
  .welcome {
    @include f-inter-bold(1.6rem, 1.9rem);
    color: var(--primary-color);
    margin-bottom: 8px;
  }
  .question {
    @include f-inter-regular(2.4rem, 2.9rem);
  }
  .form {
    @include flex-column(center);
    @include small {
      width: 100%;
    }
    input {
      border: none;
      text-align: center;
      @include sm-desktop-down {
        @include f-inter-regular(2.8rem, 3.2rem);
      }
      @include sm-desktop-up {
        @include f-inter-regular(4rem, 4.8rem);
        width: 500px;
      }
      &::placeholder {
        @include f-inter-regular(4rem, 4.8rem);
        color: var(--text-tertiary);
      }
    }
    .email {
      @include flex-column(center);
      width: 100%;
      margin: 64px 0;
      @include small {
        padding: 0;
        input {
          width: 100%;
        }
      }
      @include large {
        padding: 0 20px;
      }
    }
  }
  .error {
    margin-top: 10px;
    color: var(--complementary-secondary);
  }
  .saveforlater {
    @include flex-column(center, center);
    @include small {
      width: 100%;
    }
    @include large {
      width: 360px;
    }
    .emoji {
      @include f-inter-regular(2.4rem, 2.8rem);
      padding-bottom: 12px;
    }
    .title {
      @include f-inter-bold(1.8rem, 2.2rem);
      padding-bottom: 24px;
    }
    .desc {
      @include f-inter-regular(1.5rem, 2.1rem);
      color: rgba(9, 20, 37, 0.74);
      padding-bottom: 16px;
    }
    .formSaveForLater {
      width: 100%;
      .emailSaveForLater {
        padding-bottom: 32px;
        input {
          &:focus {
            border: 1px solid rgba(9, 20, 37, 0.2);
          }
        }
      }
      .buttonSaveForLater {
        width: 100%;
        border-radius: 20px;
        &:disabled {
          background: rgba(9, 20, 37, 0.08);
          color: rgba(9, 20, 37, 0.6);
        }
      }
    }
  }
  .aiSection {
    @include flex-column(center, center);
    @include mobile {
      width: 100%;
      padding: 0 16px;
    }
    @include desktop {
      width: 392px;
      margin: 0 auto;
    }
    .logo {
      @include mobile {
        position: absolute;
        top: 28px;
      }
      @include desktop {
        position: absolute;
        width: 88px;
        height: 20px;
        top: 26px;
        left: 4.5%;
        z-index: 1;
      }
    }
    .step {
      @include mobile {
        display: none;
      }
      position: absolute;
      top: 42px;
      @include f-inter-bold(1.8rem, 2.8rem);
      color: var(--primary-color-light);
    }
    .section {
      @include flex-column;
      align-items: center;
      width: 100%;
      .emoji {
        font-size: 36px;
      }
      .title {
        @include f-inter-bold(2.4rem, 3.3rem);
        color: var(--primary-color);
      }
      &Form {
        width: 100%;
      }
      .input {
        padding: 64px 0 24px;
      }
      .button {
        width: 100%;
      }
    }
  }
}
