@import 'styles/common.scss';

.SaveInApp {
  @include flex-column;
  position: relative;
  .saved {
    padding: 20px 0;
    @include flex-column(center, center);
    .emoji {
      @include f-inter-regular(3.2rem, 2.5rem);
    }
    .title {
      @include f-inter-bold(1.8rem, 2.2rem);
      padding-top: 16px;
      padding-bottom: 24px;
    }
    .desc {
      @include f-inter-regular(1.5rem, 2.1rem);
      color: rgba(9, 20, 37, 0.74);
      text-align: center;
    }
    .download {
      margin: 32px 0;
      padding: 10px 14px;
    }
    .continue {
      @include f-inter-semi-bold(1.5rem, 2.1rem);
    }
  }
}
